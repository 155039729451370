import colors from "theme/colors"
import { designSystem } from "@planningcenter/tapestry-react"

// Themes for buttons in tapestry-react
// https://planningcenter.github.io/tapestry-react/theming#themes

const themes = {
  primary: {
    fill: {
      backgroundColor: "primary",
      color: "white",
      hover: { backgroundColor: "primaryBtnFocusColor" },
      active: { backgroundColor: "base1" },
    },
    outline: {
      color: "primary",
      stroke: "primary",
      backgroundColor: "transparent",
      hover: {
        backgroundColor: "white",
        color: "primary",
      },
      active: {
        color: colors.base1,
        backgroundColor: "white",
      },
    },
    naked: {
      color: "primary",
      hover: { backgroundColor: "ice" },
    },
  },
  secondary: {
    fill: {
      backgroundColor: "white",
      color: "primary",
      hover: { backgroundColor: "ice" },
    },
    outline: {
      backgroundColor: "transparent",
    },
    naked: {
      color: "primary",
    },
  },
  drawerPrimary: {
    fill: {
      backgroundColor: "neutral080",
      color: "primary",
      hover: { backgroundColor: "neutral070" },
    },
    outline: {
      backgroundColor: "transparent",
    },
    naked: {
      color: "primary",
    },
  },
  sidebarFilter: {
    naked: {
      justifyContent: "start",
      fontWeight: 600,
      color: "base0",
      paddingTop: "7px",
      paddingBottom: "7px",
      hover: {
        backgroundColor: "base0",
        color: "white",
      },
    },
    nakedNoHover: {
      justifyContent: "start",
      fontWeight: 600,
      color: "base0",
      paddingTop: "7px",
      paddingBottom: "7px",
    },
    fill: {
      backgroundColor: "primary-lightest",
      paddingTop: "7px",
      paddingBottom: "7px",
      color: "primary",
      fontWeight: 600,
    },
  },
  pageHeader: {
    fill: {
      backgroundColor: "primary",
      borderColor: "primary",
      color: "white",
      hover: {
        backgroundColor: "productPeople54",
        borderColor: "productPeople54",
      },
    },
    primary: {
      backgroundColor: "white",
      borderColor: "white",
      color: "#244490",
      hover: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
  },
}

// Temporarily update theme variants until Tapestry-React is up-to-spec

const { defaultTheme } = designSystem
const buttonThemes = Object.keys(defaultTheme.button.themes)
const buttonVariants = ["fill", "outline", "naked"]

buttonThemes.forEach(theme => {
  buttonVariants.forEach(variant => {
    themes[theme] = themes[theme] || {}
    themes[theme][variant] = themes[theme][variant] || {}
    themes[theme][variant].fontWeight = 400
  })
})

export default { themes }
